import { Raffle, RaffleUserShort } from "types/raffles";

export interface CoinBox {
  id: number;
  title: string;
  cooldownSec: number;
  description: string;
  coinBoxItems: CoinBoxItem[];
  type: CoinBoxType;
  isEnabled: boolean;
}

export interface CoinBoxPublic {
  id: number;
  title: string;
  cooldownSec: number;
  description: string;
  coinBoxItems: CoinBoxItem[];
  type: CoinBoxType;
  min: number;
  max: number;
  coinBoxLatestHistory: CoinBoxHistoryItem[];
}

export interface CoinBoxHistoryItem {
  coinBoxId: number;
  openedAt: string;
  userId: string;
}

export interface CoinBoxHistoryAllDto {
  id: number;
  coinBoxId: number;
  reward: number;
  rewardType: CoinBoxItemRewardType;
  openedAt: string;

  coinBox: Pick<CoinBox, "type">;
  user: Pick<RaffleUserShort, "displayName">;
}

export interface CoinBoxItem {
  id?: number | string;
  reward: number;
  probability: number;
  rewardType: CoinBoxItemRewardType;
  coinBoxId?: number;
}

export enum DailyClaimerRewardType {
  TINY = "TINY",
  SMALL = "SMALL",
  MODERATE = "MODERATE",
  DECENT = "DECENT",
  LARGE = "LARGE",
  HUGE = "HUGE",
  TREASURE = "TREASURE",
  SECRET = "SECRET",
}

export enum CoinBoxItemRewardType {
  TINY = "TINY",
  SMALL = "SMALL",
  MODERATE = "MODERATE",
  DECENT = "DECENT",
  LARGE = "LARGE",
  HUGE = "HUGE",
  TREASURE = "TREASURE",
}
export enum CoinBoxType {
  MINUTE = "MINUTE",
  HOURLY = "HOURLY",
  DAILY = "DAILY",
  WEEKLY = "WEEKLY",
}

export const BoxPropsMapper: Record<CoinBoxType, { color: string; image: string }> = {
  [CoinBoxType.MINUTE]: {
    color: "#444863",
    image: "/images/Treasury/boxes/minute.png",
  },
  [CoinBoxType.HOURLY]: {
    color: "#01aaff",
    image: "/images/Treasury/boxes/hourly.png",
  },
  [CoinBoxType.DAILY]: {
    color: "#db01ff",
    image: "/images/Treasury/boxes/daily.png",
  },
  [CoinBoxType.WEEKLY]: {
    color: "#ff9f05",
    image: "/images/Treasury/boxes/weekly.png",
  },
};

export type DetailsValidationErrorKeys = keyof DailyClaimerDetails;
export type DailyClaimerCreateFromValidationErrorKeys = keyof DailyClaimerCreateFromRequestDto;
export type StreakValidationErrorKeys =
  | keyof StreakRaffleRules
  | keyof StreakPoints
  | keyof { daysForStreak: number };

export enum DailyClaimerStreak {
  Points = "Points",
  Raffle = "Raffle",
}

export interface DailyClaimerCreateFromRequestDto {
  codeFrom: string;
  codeNew: string;
  year: number;
  month: number;
}
export interface DailyClaimerStreakDto {
  daysForStreak: number;
  streakGpoints: number | null;
  raffleConfig: StreakRaffleRules | null;
}

export interface DailyClaimerDto {
  code: string;
  title: string;
  year: number;
  month: number;
  description: string | undefined;
  streakConfig: DailyClaimerStreakDto;
  imageFile: File | null;
}

export interface StreakRaffleRules {
  winnersCount: Raffle["winnersCount"];
  title: Raffle["title"];
  description: Raffle["description"];
  imageUrl: Raffle["imageUrl"];
  prizeInDollars: Raffle["prizeInDollars"];
  settings: Raffle["settings"];
}

export interface StreakPoints {
  streakGpoints: number | null;
}

export interface StreakPoints {
  streakGpoints: number | null;
}

export interface DailyClaimerDetails {
  code: string;
  title: string;
  year: number;
  month: number;
  description: string | undefined;
}

export interface DailyClaimer {
  code: string;
  createdAt: string;
  updatedAt: string;
  title: string;
  description: string;
  startDate: string;
  endDate: string;
  year: number;
  month: number;
  daysForStreak: number;
  streakGpoints: number | null;
  streakRaffleRules: StreakRaffleRules | null;
  raffleId: string | null;
  raffle: Raffle | null;
  days: DayPrize[];
}

export interface DayPrize {
  isLocked: boolean;
  isClaimed: boolean;
  isStreak: boolean;
  title: string;
  isSecret: boolean;
  reward: number;
  rewardType: DailyClaimerRewardType;
  createdAt: string;
  updatedAt: string;
  day: number;
  startDate: string;
  endDate: string;
  dailyClaimerCode: string;
  canBeClaimed: boolean;
}

export type DayShort = Pick<
  DayPrize,
  "isClaimed" | "isSecret" | "isStreak" | "reward" | "rewardType" | "day" | "canBeClaimed"
>;

export interface CoinBoxHistoryItem {
  id: number;
  coinBoxId: number;
  userId: string;
  reward: number;
  rewardType: string;
  openedAt: string;
  coinBoxFairnessHistoryId: number;
  coinBox: {
    type: string;
  };
  coinBoxFairnessHistory: {
    clientSeed: string;
    serverSeed: string;
  };
}
