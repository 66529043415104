import React from "react";
import { LeaderboardWinnersBlock } from "components";
import {
  IDtoLeaderboard,
  IDtoLeaderboardUser,
  LeaderboardPrizeSelectionType,
  TheLeaderboardSourceKeys,
} from "interfaces";
import { IAppContext, withApp } from "contexts";
import { CasinoProvider } from "./context/CasinoProviderContext";
import useLeaderboardCurrentUser from "../../hooks/leaderboards/useLeaderboardCurrentUser";
import useLeaderboardInfo from "../../hooks/leaderboards/useLeaderboardInfo";
import useLeaderboardLeaders, {
  LeaderboardLeadersViewState,
} from "../../hooks/leaderboards/useLeaderboardLeaders";

interface ILeaderboardWinners {
  id?: string;
  nextScollHref?: string;
  switcher?: string;
  showBlockHeader?: boolean;
  showLeaderboardEndsIn?: boolean;
  showLeaderboardUserList?: boolean;
  showIntervalUpdate?: boolean;
  showActiveLeaderboardButton?: boolean;
}

const LeaderboardWinnersScreenPure: React.FC<ILeaderboardWinners & IAppContext> = ({
  id,
  showBlockHeader = true,
  showLeaderboardEndsIn = false,
  showLeaderboardUserList = false,
  showIntervalUpdate = true,
  showActiveLeaderboardButton = true,
}): React.ReactElement => {
  const [switcher, setSwitcher] = React.useState<string>(TheLeaderboardSourceKeys.gamdom);

  const { data: leaderboard } = useLeaderboardInfo({ casinoProvider: switcher });
  const { data: leaderboardUsers } = useLeaderboardLeaders({
    casinoProvider: switcher,
    viewState: LeaderboardLeadersViewState.COLLAPSED,
  });

  const { data: leaderboardLoggedIn } = useLeaderboardCurrentUser(leaderboard?.id);

  const onSetSwitcher = (value: string) => {
    setSwitcher(value);
  };

  return (
    <div id={id}>
      <LeaderboardWinnersBlock
        switcher={switcher}
        setSwitcher={onSetSwitcher}
        leaderboard={leaderboard}
        leaderboardWinners={
          leaderboard?.prizeSelectionType === LeaderboardPrizeSelectionType.RAFFLE
            ? []
            : leaderboardUsers.slice(0, 3) ?? []
        }
        leaderboardUsers={
          leaderboard?.prizeSelectionType === LeaderboardPrizeSelectionType.RAFFLE
            ? leaderboardUsers ?? []
            : leaderboardUsers.slice(3) ?? []
        }
        showBlockHeader={showBlockHeader}
        showLeaderboardEndsIn={showLeaderboardEndsIn}
        showLeaderboardUserList={showLeaderboardUserList}
        showIntervalUpdate={showIntervalUpdate}
        showActiveLeaderboardButton={showActiveLeaderboardButton}
        currentUserPosition={leaderboardLoggedIn}
      />
    </div>
  );
};

export const LeaderboardWinnersScreen = withApp(LeaderboardWinnersScreenPure);
