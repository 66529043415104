export function buildQueryParam(key: string, value: any) {
  return `${value !== null && value !== undefined ? `${key}=${value}` : ""}`;
}

export function buildQueryParams(queryParams: string[]): string {
  const notEmptyParams = queryParams.filter((str) => str.length > 0);
  if (notEmptyParams.length === 0) return "";
  const query = `?${notEmptyParams.join("&")}`;
  return query;
}
