import React from "react";
import styled from "styled-components";
import {
  Ident,
  TheIcon,
  LeaderboardEndsIn,
  LeaderboardWinnersBlock,
  IStyles,
  LeaderboardUsersTable,
} from "components";
import { LeaderboardPrizeSelectionType, TheLeaderboardSourceKeys } from "interfaces";
import { IAppContext, withApp } from "contexts";
import useLeaderboardCurrentUser from "hooks/leaderboards/useLeaderboardCurrentUser";
import useLeaderboardLeaders, {
  LeaderboardLeadersViewState,
} from "hooks/leaderboards/useLeaderboardLeaders";
import useLeaderboardInfo from "hooks/leaderboards/useLeaderboardInfo";
import { CasinoProvider } from "constants/leaderboard";

export interface ILeaderboardWinnersLayout extends IAppContext {
  showLeaderboardUserList?: boolean;
  showIntervalUpdate?: boolean;
}

export const LeaderboardWinnersLayoutPure: React.FC<ILeaderboardWinnersLayout> = ({
  showLeaderboardUserList = false,
  showIntervalUpdate = true,
}): React.ReactElement => {
  const [switcher, setSwitcher] = React.useState<string>(CasinoProvider.GAMDOM);
  const { data: leaderboard } = useLeaderboardInfo({ casinoProvider: switcher });
  const { data: leaderboardUsers } = useLeaderboardLeaders({
    casinoProvider: switcher,
    viewState: LeaderboardLeadersViewState.COLLAPSED,
  });
  const { data: leaderboardLoggedInUser } = useLeaderboardCurrentUser(leaderboard?.id);

  const onSetSwitcher = (value: string) => {
    setSwitcher(value);
  };

  return (
    <Box>
      <Ident height={54} />
      <BoxItem>
        <TheIcon icon="wcrown:40" size={40} />
      </BoxItem>
      <Ident height={8} />
      <BoxH1>
        <span>TOTAL </span>
      </BoxH1>
      <Ident height={24} />
      <BoxItem>
        {leaderboard && leaderboardUsers ? (
          <LeaderboardWinnersBlock
            switcher={switcher}
            setSwitcher={onSetSwitcher}
            leaderboard={leaderboard}
            leaderboardWinners={
              leaderboard?.prizeSelectionType === LeaderboardPrizeSelectionType.RAFFLE
                ? []
                : leaderboardUsers.slice(0, 3) ?? []
            }
            leaderboardUsers={
              leaderboard?.prizeSelectionType === LeaderboardPrizeSelectionType.RAFFLE
                ? leaderboardUsers ?? []
                : leaderboardUsers.slice(3) ?? []
            }
            showIntervalUpdate={showIntervalUpdate}
            currentUserPosition={leaderboardLoggedInUser}
          />
        ) : null}
      </BoxItem>

      {showLeaderboardUserList && !!leaderboard ? (
        <BoxItem>
          <LeaderboardEndsIn value={leaderboard?.endDate ?? null} start={leaderboard?.startDate} />
          <Ident height={38} />
          <LeaderboardUsersTable
            $keys={[
              "#",
              "displayName",
              "value",
              ...(leaderboard?.prizeSelectionType === LeaderboardPrizeSelectionType.RAFFLE
                ? ["tickets"]
                : []),
              "prize",
            ]}
            leaderboard={leaderboard}
            leaderboardUsers={leaderboardUsers}
            usersSkip={0}
            usersTake={10}
            ticketRate={leaderboard?.ticketRate}
          />
        </BoxItem>
      ) : showLeaderboardUserList ? (
        <Ident height={70} />
      ) : null}
    </Box>
  );
};

export const LeaderboardWinnersLayout = withApp(LeaderboardWinnersLayoutPure);

const Box = styled.div<IStyles>`
  box-sizing: border-box;
  text-align: center;
  ${({ styles }) => styles}
`;

const BoxItem = styled.div<IStyles>`
  box-sizing: border-box;
  max-width: 1007px;
  align-items: center;
  margin: 0 auto;
  ${({ styles }) => styles}
`;

const BoxH1 = styled.div`
  display: flex;
  justify-content: center;
  color: #d2eaff;
  font-size: 40px;
  font-style: italic;
  font-weight: 900;
  letter-spacing: 0.03em;
  line-height: 130%;
  text-transform: uppercase;

  & [data-blue] {
    color: #3b8be9;
  }
`;
