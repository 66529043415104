import { IDtoLeaderboard } from "interfaces";
import { useAppContext } from "contexts";
import { useQuery } from "@tanstack/react-query";
import { buildQueryParam, buildQueryParams } from "utils/api/api-utils";
import { ApiDataResponse } from "types/api";

export default function useLeaderboardInfo({
  casinoProvider,
  leaderboardId,
}: {
  casinoProvider?: string;
  leaderboardId?: string;
}) {
  const { apiService } = useAppContext();

  return useQuery<IDtoLeaderboard>({
    queryKey: ["leaderboard", { casinoProvider, leaderboardId }],
    queryFn: async () => {
      const controller = "/leaderboard";
      const action = "/leaderboard-info";

      const leaderboardIdParam = buildQueryParam("leaderboardId", leaderboardId);
      const casinoProviderParam = buildQueryParam("casinoProvider", casinoProvider);
      const queryParams = buildQueryParams([leaderboardIdParam, casinoProviderParam]);

      const finalUrl = `${controller}${action}${queryParams}`;
      const response = await apiService.get<ApiDataResponse<IDtoLeaderboard>>(finalUrl);
      return response.data;
    },

    retry: false,
    enabled: !!casinoProvider || !!leaderboardId,
  });
}
