import { useAppContext } from "contexts";
import { IDtoLeaderboardUser, TheLeaderboardSourceKeys } from "interfaces";
import { useQuery } from "@tanstack/react-query";
import { ApiDataResponse } from "types/api";
import { buildQueryParam, buildQueryParams } from "utils/api/api-utils";

interface LeaderboardUsersParams {
  casinoProvider?: string;
  viewState: LeaderboardLeadersViewState;
  leaderboardId?: string;
}

export enum LeaderboardLeadersViewState {
  EXPANDED = "expanded",
  COLLAPSED = "collapsed",
}

export default function useLeaderboardLeaders({
  viewState,
  casinoProvider,
  leaderboardId,
}: LeaderboardUsersParams) {
  const { apiService } = useAppContext();

  return useQuery<IDtoLeaderboardUser[]>({
    queryKey: ["leaderboard-leaders", { leaderboardId, casinoProvider, viewState }],
    queryFn: async () => {
      const controller = "/leaderboard-user";
      const action = "/leaderboard-leaders";

      const viewStateParam = buildQueryParam("viewState", viewState);
      const leaderboardIdParam = buildQueryParam("leaderboardId", leaderboardId);
      const casinoProviderParam = buildQueryParam("casinoProvider", casinoProvider);

      const queryParams = buildQueryParams([
        leaderboardIdParam,
        casinoProviderParam,
        viewStateParam,
      ]);

      const finalUrl = `${controller}${action}${queryParams}`;
      const response = await apiService.get<ApiDataResponse<IDtoLeaderboardUser[]>>(finalUrl);
      return response.data;
    },
    retry: false,
    enabled: !!leaderboardId || !!casinoProvider,
    placeholderData: (previousData) => previousData,
    initialData: [],
  });
}
